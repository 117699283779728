<template>
  <v-dialog :value="true" max-width="500px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h6">Редактирование</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="searchedTargetTicker"
                :items="tickers"
                clearable
                searchable
                hide-overlay
                label="Тикер США"
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field placeholder="Поиск" clearable @input="searchStocks" />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                type="number"
                :value="stock.coefficient"
                label="Коэффициент"
                @input="setField('coefficient', $event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')">Отмена</v-btn>
        <v-btn color="blue darken-1" text @click="$emit('save')">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    stock: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tickers: this.stock.targetTicker ? [this.stock.targetTicker] : []
    };
  },
  computed: {
    searchedTargetTicker: {
      get() {
        return this.stock.targetTicker;
      },

      set(value) {
        this.setField("targetTicker", value);
      }
    }
  },
  methods: {
    async searchStocks(title) {
      if (!title) {
        this.tickers = [];

        return;
      }

      try {
        const { data: stocks } = await this.$axios.$get("stocks", { params: { title, sort: { by: "title" } } });

        this.tickers = stocks.map(({ ticker }) => ticker);
      } catch {
        this.tickers = [];
      }
    }
  }
};
</script>
