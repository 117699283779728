<template>
  <div>
    <h2 class="mb-4">Матчинг акций</h2>

    <stocks-matching-filters class="mb-5" :table-params="tableParams" @change-field="changeTableParams" />

    <data-table
      ref="dataTable"
      url="stocks/hong-kong/map"
      item-key="ticker"
      clickable-rows
      :headers="headers"
      :table-params="tableParams"
    >
      <template v-slot:[`item.createdAt`]="{ item: stock }">
        {{ stock.createdAt | date }}
      </template>

      <template v-slot:[`item.actions`]="{ item: stock }">
        <v-btn icon color="accent" @click="openEditStock(stock)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </data-table>

    <edit-stock-matching-modal
      v-if="activeStock !== null"
      :stock="activeStock"
      @change-field="changeField"
      @close="resetStockFields"
      @save="saveStock"
    />
  </div>
</template>

<script>
import { UpdateHongkongStockDto } from "~/libs/stock/update-stock-dto";
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import StocksMatchingFilters from "~/components/stocks/matching/filters";
import EditStockMatchingModal from "~/components/stocks/matching/edit-modal";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "Компания", value: "company", sortable: true },
        { text: "Тикер Гонконг", value: "ticker", sortable: true, class: "nobr" },
        { text: "ISIN Гонконг", value: "isin", sortable: true },
        { text: "Тикер США", value: "targetTicker", sortable: true, class: "nobr" },
        { text: "ISIN США", value: "targetIsin", sortable: true },
        { text: "Коэффициент", value: "coefficient", sortable: true, class: "nobr" },
        { text: "Дата создания", value: "createdAt", sortable: true, class: "nobr" },
        { text: "Редактирование", value: "actions", sortable: false }
      ],
      activeStock: null,
      updatePeriod: { min: null, max: null },
      tableParams: {
        mapped: null,
        title: ""
      }
    };
  },
  methods: {
    openEditStock(stock) {
      this.activeStock = { ...stock };
    },
    resetStockFields() {
      this.activeStock = null;
    },
    async saveStock() {
      try {
        await this.$axios.$put(`stocks/hong-kong/map`, new UpdateHongkongStockDto(this.activeStock));
        await this.$refs.dataTable.update();

        this.$snackbar.success("Тикер США обновлен");
      } catch (e) {
        const html = new ApiValidationError(e, ["targetTicker"]).toHtml();

        this.$snackbar.error("Не удалось обновить тикер США: " + html);
      } finally {
        this.activeStock = null;
      }
    },
    changeTableParams(payload) {
      this.commitChange(this.tableParams, payload);
    },
    changeField({ field, value }) {
      this.activeStock[field] = value;
    }
  },
  components: {
    StocksMatchingFilters,
    EditStockMatchingModal
  }
};
</script>
