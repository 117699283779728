<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field label="Поиск" clearable @input="setField('title', $event)" />
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-select
          :value="tableParams.mapped"
          :items="byMatching"
          label="Сопоставленные акции"
          item-value="uid"
          item-text="text"
          @input="setField('mapped', $event)"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      byMatching: [
        { uid: null, text: "Все" },
        { uid: true, text: "Сопоставленные" },
        { uid: false, text: "Не сопоставленные" }
      ]
    };
  }
};
</script>
