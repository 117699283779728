export class UpdateStockDto {
  constructor(stock, logo = null) {
    this.logo = logo;
    this.name = stock.name;
    this.nameCases = stock.nameCases;
    this.company = stock.company;
    this.status = stock.status;
    this.country = stock.country;
    this.description = stock.description;
    this.mobileDescription = stock.mobileDescription;
    this.type = stock.type;
    this.exchange = stock.exchange;
    this.ceo = stock.ceo;
    this.address = stock.address;
    this.employees = stock.employees;
    this.site = stock.site;
    this.sector = stock.sector;
    this.statistic = stock.statistic;
    this.indexableDescription = stock.indexableDescription ? 1 : 0;
    this.inSpbe = stock.inSpbe ? 1 : 0;
    this.isin = stock.isin;
  }
}

export class UpdateHongkongStockDto {
  constructor(stock) {
    this.ticker = stock.ticker;
    this.targetTicker = stock.targetTicker;
    this.coefficient = stock.coefficient;
  }
}

export class UpdateRuStockDividendDto {
  constructor(dividend) {
    this.amount = dividend.amount;
    this.buyBefore = dividend.buyBefore;
    this.paymentDate = dividend.paymentDate;
    this.recordDate = dividend.recordDate;
    this.currency = dividend.currency;
    this.frequency = dividend.frequency;
    this.stockPrice = dividend.stockPrice;
    this.status = dividend.status;
  }
}

export class UpdateForecastDto {
  constructor(forecast) {
    this.ticker = forecast.ticker;
    this.analystId = forecast.analystId;
    this.recommendation = forecast.recommendation;
    this.priceTarget = forecast.priceTarget;
    this.date = forecast.date;
    this.expiredAt = forecast.expiredAt;
    this.article = typeof forecast.article === "object" ? forecast.article.url : forecast.article;
  }
}

export class UpdateStockSplitDto {
  constructor(split) {
    this.date = split.date;
    this.to = split.to;
    this.from = split.from;
    this.status = split.status;
  }
}
